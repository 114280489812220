@import '_reset.scss';
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/elements/box.sass";
@import "bulma/sass/grid/columns.sass";
@import '~react-leaflet-markercluster/dist/styles.min.css';


html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-weight: normal;
	vertical-align: baseline;
	background: transparent;
}

html {
    overflow: hidden;
}

html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
}

h1 {
    font-size: 25px;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 20px;
}

.column {
    padding: 0px;
}

.fill {
    flex-grow: 1;
    min-width: 2%;
}

.App {
    height: 100%;
    display: flex;
}

.leaflet-pane {
    z-index: 100;
}

.leaflet-atd-pane {
    z-index: 150;
}

.leaflet-tooltip-pane {
    z-index: 300;
}

.leaflet-marker-pane {
    z-index: 200;
}

.List {
    display: grid;
}

.LoadingIndicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(gray, 0.4);
    z-index: 2000;

    & .caption {
        @extend .box;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.MarkerCluster {
    background-color: rgba(green, 0.7);
    border-radius: 100px;
    text-align: center;
    justify-content: center;
}

textarea {
    width: 100%;
    resize: none;
}

.map {
    flex-grow: 1;
    display: contents;

    & > * {
        width: 100%;
    }

    & .panes {
        z-index: 1000;
        cursor: initial;
        width: 500px;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(white, 0.8);
        padding: 1%;
        height: 100vh;

        & > * {
            height: 100%;
        }
    }

    & .tiles {
        opacity: 1;

        &.transparent {
            opacity: 0.4 !important;
        }
    }

    & .wait-for-atd {
        @extend .box;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.marker-tooltip {
    min-width: 200px;
    white-space: normal;
}

.traffic-line {
    stroke-dasharray: 2px 12px;
    stroke-linecap: round;
    stroke-width: 2px;
    fill: none;
    stroke: black;
    stroke-width: 1px;

    @keyframes track_direction {
        from { stroke-dashoffset: 0}
        to { stroke-dashoffset: -16px}
      }

    cursor: grab;
    stroke-dasharray: none;
    stroke-dasharray: 8px;
    animation: track_direction 1.6s linear forwards infinite;
}

.Nav {
    width: fit-content;
    width: -moz-fit-content;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;

    & button {
        color: gray;
        width: fit-content;
        padding: 10px;

        &:hover {
            color: white;
        }
    }

    & .login {
        margin-top: auto;
    }
}

.LoginPane {
    cursor: unset;

    .controls {
        height: auto;
        display: grid;
        grid-template-columns: 20% 80%;
        & h2, & button, & .error {
            grid-column: 1 / span 2;
        }

        & > * {
            margin-top: 5px;
        }
    }
}

.ObjectsPane {
    & .MapObjectType {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    & .subtypes {
        margin-left: 5%;
    }

    & .MapObjectSubtype {
        display: grid;
        grid-template-columns: 30px auto 5ex 5ex;
        & .icon {
            justify-self: center;
            color: gray;
            cursor: pointer;
        }
        & .icon.visible {
            color: inherit;
        }
        & .icon:hover {
            color: inherit;
        }
        & .name {
        }
        & .qty {
            text-align: right;
            margin-right: 2em;
        }

        & .add, & .list {
            text-align: center;
            display: none;
            color: gray;
            margin-left: 4px;
        }

        & .add:hover, & .list:hover {
            color: black;
        }
    }

    & .MapObjectSubtype:hover {
        & .add, & .list {
            display: unset;
        }
    }

    & .selectedRegion {
        display: inline-flex;
        & .clear {
            margin-left: 2em;
        }
    }
}

.TrafficPane {
    & .TrafficPaneObject {
        display: grid;
        grid-template-columns: 30px auto 5ex;
        & .icon {
            justify-self: center;
            color: black;
            cursor: unset;
        }
        & .name {
        }

        & .add {
            text-align: center;
            display: none;
            color: gray;
        }

        & .add:hover {
            color: black;
        }
    }

    & .TrafficPaneObject:hover {
        & .add {
            display: unset;
        }
    }

    & .add {
        text-align: center;
    }
}

.ListPaneItem {
    display: grid;
    grid-template-columns: auto 15%;

    & .controls {
        margin-left: auto;
    }
}

.ListPane {
    overflow-y: scroll;
    & .head {
        display: grid;
        grid-template-columns: auto auto;

        & .controls {
            margin-left: auto;
        }
    }
}

.ListPaneSearch {
    margin: 0.5em 0;
}

.RegOperatorsTitle{
    z-index: 1000;
    cursor: initial;
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(white, 0.6);
    padding: 0.3em;

    p {
        font-weight: bold;
    }
}
.RegOperatorsLegend {

    display: grid;
    grid-template-columns: 20% 80%;

    & .color {
        width: 80%;
        height: 14px;
        border: black;
        opacity: 0.5;
        margin: 5%;
    }
}

.EditPane, .BalancePane, .DictsPane, .ReportsPane {
    overflow-y: scroll;
    overflow-x: hidden;

    & .head {
        display: grid;
        grid-template-columns: auto auto;

        & .controls {
            margin-left: auto;
        }

        & .error {
            font-size: large;
            text-align: center;
            width: 100%;
            color: brown;
            grid-column: 1 / span 2;
        }
    }
}

.ReportsPane .List button {
    text-align: left !important;
}

.PageControl {
    text-align: center;
}

.ControlsList {
    display: grid;
    grid-template-columns: 30% 65%;
    gap: 2px;

    & .embedded {
        grid-column: 1 / span 2;
    }

    & h4 {
        grid-column: 1;
    }

    & .Input {
        grid-column: 2;
        width: 100%;
    }
}

.RecordInput {
    display: grid;
    grid-template-columns: 30% 65%;
    gap: 2px;
}

.ArrayInput {
    & .item {
        display: grid;
        grid-template-columns: 30% 65% auto;
        gap: 2px;

        & h4 {
            grid-column: 1;
        }

        & .Input {
            grid-column: 2;
            width: 100%;
        }

        & .del {
            grid-column: 3;
            grid-row: 1 / span all;
        }
    }

    & .add {
        text-align: center;
    }
}

.InlineArray {
    & .item {
        display: grid;
        grid-template-columns: 95% 5%;
        gap: 2px;

        & .Input {
            grid-column: 1;
            width: 100%;
        }

        & .del {
            grid-column: 2;
            grid-row: 1 / span all;
        }
    }

    & .add {
        text-align: center;
    }
}

.WasteGenItemsInput {
    & .item {
        display: grid;
        grid-template-columns: 65% 30% auto;
        gap: 2px;
    }

    & h4 {
        grid-column: unset !important;
    }

    & .Input {
        grid-column: unset !important;
    }

    & .add {
        grid-column: 1 / span all;
        text-align: center;
    }
}

select, input {
    background-color: white;
    border-width: 0.5px;
}

button {
    &:focus {
        outline: none;
    }
    background-color: transparent;
    border: hidden;
    color: gray;

    &:hover {
        color: black;
    }
}

a {
    &:link, &:visited {
        color: gray;
    }
    &:hover {
        color: black;
    }
}

.EditableMarker {
    border-radius: 50%;
    border: 10px solid #fff;
    width: 10px;
    height: 10px;

    & .icon {
        position: absolute;
        transform: translate(-50%,-25%);
    }

    &::after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        bottom: -30px;
        left: 0px;
        border: 10px solid transparent;
        border-top: 17px solid #fff;
    }
}
.ZigZagLabel {
    position: absolute;
    z-index: 1000;
    bottom: 13px;
    right: 3px;

    & img {
        height: auto;
        opacity: 0.5;
    }
    & img:hover {
        opacity: 0.8;
    }
}